/* .fa-insta {
  
}
.icons-style {
  color: rgb(221, 51, 79);
  font-size: 24px;
  padding: 0px;
}
.whats-app {
  color: green;
  font-size: 24px;
  padding: 0px;
}
.ant-float-btn-group .ant-float-btn {
  height: 50px;
  width: 50px;
}
.ant-float-btn-default
  .ant-float-btn-body
  .ant-float-btn-content
  .ant-float-btn-icon {
  margin-left: -7px;
}
.ant-float-btn .ant-float-btn-body {
  flex: none;
} 
 .anticon {
  margin-top: 0px;
  font-size: 24px;
}  */
.whatsapp-icon {
  position: fixed;
  bottom: 35px;
  right: 20px;
  /* width: 50px;
  height: 50px; */
  z-index: 1000;
  cursor: pointer;
}
.whatsapp-icon .fa-whatsapp {
  background-color: rgb(37, 211, 102);
  color: white;
  font-size: 35px;
  padding: 15px;
  border-radius: 50%;
}
.whatsapp-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(37, 211, 102, 0.3); /* Adjust color and transparency */
  animation: spread 1.5s infinite;
  z-index: -1;
}

@keyframes spread {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
