.text {
  font-size: 18px;

  background-color: rgb(247, 245, 254);
}
.abc {
  background-color: white;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown:hover .submenu {
  display: none;
}
.dropdown-menu .mainmenu:hover .submenu {
  display: block;
}
.dropdown:hover .submenu_1 {
  display: none;
}
.mainmenu_1:hover .submenu_1 {
  display: block;
}
.link_font {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* color: white; */
  color: black;
  font-weight: 750;
  font-style: inherit;
  font-size: 23px;
}
.service-textstyle {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* color: white; */
  color: black;
  font-weight: 750;
  font-style: inherit;
  font-size: 18px;
}
.dropdown-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: orange;
}

.dropdown-item:hover .doubledoor-dropdown {
  display: block;
}
.dropdown-menu a {
  padding: 7px;
}
.quote-div {
  background-color: #141f39;
  border-radius: 50px;

  font-weight: 550;
  padding: 5px 15px 5px 15px;
}
.quote-div:hover {
  background-color: #ff9000;
  color: white;
}
.Socialmedia-links {
  display: none;
}

/* dropdown-style */
.address-text {
  font-size: 15px;
}
.section .link {
  border-radius: 50px;
  border: 1px solid black;
  height: 40px;
  width: 40px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.fa-facebook-f {
  color: black;
}
.section .link:hover {
  background-color: blue;
  border: 1px solid blue;
  color: white;
}
.fa-facebook-f:hover {
  color: white;
}
.section .insta-link {
  border-radius: 50px;
  border: 1px solid black;
  height: 40px;
  width: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.fa-instagram {
  color: black;
}
.section .insta-link:hover {
  background-color: rgb(246, 124, 144);
  border: 1px solid rgb(246, 124, 144);
}
.fa-instagram:hover {
  color: white;
}
.fa-linkedin-in {
  color: black;
}
.fa-linkedin-in:hover {
  color: white;
}
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 18px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .nav-item .submenu_1 {
    display: none;
    position: absolute;
    left: 100%;
    top: 18px;
  }
  .nav-item .submenu_1-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
}
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
  .quote-div {
    display: none;
  }
  .Socialmedia-links {
    display: block;
  }
  .nav-link {
    margin-top: -10px;
  }
}
