.image-div {
  height: 500px;
  width: 500px;
  object-fit: contain;
}
.nave {
  border: 1px solid black;
}
.globe-div {
  position: relative;
}
.globetext-div1 {
  position: absolute;
  top: 25%;
  left: 15%;
  pointer-events: auto;
  z-index: 1000;
}
.globetext-div1 h1 {
  color: white;

  font-weight: 700;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 50px;
}
.globetext-div .globe-text {
  color: white;
  font-size: 15px;
  font-weight: 700;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 50px;
}
.globe-text1 {
  color: white;
  margin-bottom: 0px;
  letter-spacing: 1px;
  font-size: 20px;
}
.video-player {
  width: 100%;
  height: 700px;
  object-fit: cover; /* Optional: Ensures the video covers the area while maintaining aspect ratio */
}
/* Styles for the curved header */
.banner-div {
  position: relative;
  overflow: hidden;
  background-color: rgb(24, 30, 78);
  border-radius: 0 0 50% 30% /0 0 50% 0%;
  transform: scale(1);
  height: auto;
}

.bannerimage-div img {
  width: 100%;
  height: 600px;
  margin-top: 40px;
}
.bannerimage-div {
  position: relative;
}
.circle-div {
  position: absolute;
  height: 500px;
  width: 650px;
  border: 3px solid white;
  z-index: -1;
  top: 3%;
  left: 3%;
  border-radius: 50%;
  border-style: dashed;
}
.face-icons {
  height: 70px;
  width: 70px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  top: 20%;
}
.insta-iconsdiv {
  height: 70px;
  width: 70px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  left: 20%;
}
.insta-iconsdiv .fa-instagram {
  position: absolute;
  top: 24%;
  left: 30%;
  font-size: 35px;
  color: rgb(229, 83, 107);
}
.face-icons .fa-facebook-f {
  color: blue;
  position: absolute;
  top: 24%;
  left: 32%;
  font-size: 35px;
}
.linked-icons {
  height: 70px;
  width: 70px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  left: 80%;
  top: 9%;
}
.linked-icons .fa-linkedin-in {
  color: blue;
  position: absolute;
  top: 24%;
  left: 32%;
  font-size: 35px;
}
.banner-div1 {
  background-color: white;
  height: 100px;
  display: none;
}
.text-div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 100px;
  margin-left: px;
}
.Experties-text {
  font-style: inherit;
  letter-spacing: 1px;
  font-size: 16px;
  color: black;
}
.Hook-style {
  color: rgba(253, 76, 12, 0.985);
}
.text-div h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 50px;
}
.text-div span {
  font-weight: 550;
}
.text-div p {
  font-size: 100%;
  font-weight: 400;
  font-style: initial;
  letter-spacing: 1px;
}
.globetext-div1 .book-button {
  cursor: pointer;
  width: 200px;
  text-align: center;
  margin-top: 20px;
  /* margin-left: 150px; */
  padding: 10px;
  border-radius: 15px;
  border: 2px solid white;
  font-weight: 500;
  font-size: 20px;
  color: white;
  background-color: orangered;
  z-index: 1;
}
.text-div .booklink {
  text-decoration: none;
}
.profile-div h2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 550;
}
.profile-div p {
  font-size: 17px;
  line-height: 30px;
}
.animate-image {
  height: 400px;
  width: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  margin-top: 10px;
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px); /* Adjust the value as needed */
  }
}

.animate-image {
  animation: moveUpDown 3s infinite; /* Adjust the duration as needed */
}
.presence-image {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}
.inline {
  display: inline;
}
.booklink {
  text-decoration: none;
}
.fa-chart-simple {
  font-size: 20px;
  color: black;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #e5e3fd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-chart-line {
  font-size: 20px;
  color: black;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #e5e3fd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.fa-handshake {
  font-size: 22px;
  color: black;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #e5e3fd;

  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-check-double {
  font-size: 22px;
  color: black;

  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #e5e3fd;

  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-thumbs-up {
  font-size: 22px;
  color: black;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #e5e3fd;

  display: flex;
  justify-content: center;
  align-items: center;
}
.digital-image {
  height: 300px;
  width: 300px;
  border-radius: 200px;
  object-fit: cover;
}

@keyframes round-div {
  0% {
    transform: translateX(80px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px);
  }
}

.digital-image {
  animation: round-div 3s infinite ease-in-out;
}
.bg-image {
  background-image: url("../image/circle.png");
  /* background-position: center; */
  background-size: cover;
}
.round-div {
  border: 20px solid rgb(216, 218, 220);
  border-radius: 200px;
  width: 340px;
}
.proces-div {
  background-color: rgb(242, 244, 248);
  /* height: 300px; */
}
.expertise-div {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 300;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
  color: #bb0b0b;
}
.proces-div span {
  font-weight: 550;
}
.Expertise-div {
  line-height: 30px;
  font-style: italic;
  font-weight: 450;
  font-size: 19px;
  color: black;
}
.image-div1 {
  height: 200px;
  width: 200px;
  border: 20px solid rgb(103, 104, 104);
  border-radius: 200px;
  margin-left: 30px;
  /* position: absolute; */
}

.discover-img {
  border-radius: 100px;
  height: 160px;
  width: 200px;
}
.discover-text {
  padding: 5px;
  border: 1px solid rgb(15, 15, 45);
  height: 35px;
  width: 35px;
  position: absolute;
  left: 51%;
  transform: translate(-50%, -50%);
  background-color: rgb(52, 52, 82);
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.discover-text p {
  color: white;
  font-weight: 650;
  margin-top: 12px;
}
.example {
  border: 1px solid black;
}
.section-text {
  color: #bb0b0b;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
}
.expertise-style {
  /* height: 500px; */
  background-color: rgb(242, 244, 248);
}
.calltoaction {
  height: 300px;
  background-color: rgb(24, 30, 78);
}
.calltoaction-text {
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */

  font-weight: 550;
}
.brand-text {
  font-size: 39px !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #bb0b0b;
}
.calltoaction-btn {
  height: 50px;
  width: 240px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid orangered;
  background-color: orangered;
  color: white;
}
.calltoaction-btn:hover {
  height: 50px;
  width: 340px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid orangered;
  background-color: orangered;
  color: white;
}
.brand-div {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 270px;
  /* text-align: center; */
  padding: 25px;
  background-color: white;
  transition: transform 0.3s ease;
}
.brand-div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 270px;

  padding: 25px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: rgb(24, 30, 78);
  color: white;
  transform: scale(1.05);
}
.brand-div:hover .view-btn {
  color: aqua;
}
.brand-container {
  background-color: rgb(242, 244, 248);
}
.brand-container .image-div1 img {
  transition: transform 0.3s ease;
}

.image-div1:hover img {
  transform: scale(1.1);
}
.about-text {
  color: #bb0b0b;
  font-weight: 700;
  font-style: inherit;
  font-size: 18px;
}
.Hook-text {
  font-style: inherit;
  font-weight: 450;
  letter-spacing: 0.1px;
  font-stretch: expanded;
  font-size: 20px;
  color: black;
}
/* p {
  font-weight: 500;
} */
.profile-div span {
  font-weight: 400;
}
/* wavestyle */
@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.header h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}
.header h5 {
  color: orangered;
  font-weight: 650;
}
.profile-div p {
  font-size: 20px;
}
/* p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
} */

.header {
  position: relative;
  /* text-align: center; */
  background: linear-gradient(
    60deg,
    rgb(55, 35, 135) 0%,
    rgb(51, 44, 112) 100%
  );
  color: white;
}
.view-btn {
  text-decoration: none;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 45vh;
  min-width: 100%;
  margin: 0;
  padding: 0;
}

.wave-headcontent {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.wave-headcontent button {
  height: 50px;
  width: 240px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid orangered;
  background-color: orangered;
  color: white;
}

.waves {
  position: relative;
  min-width: 100%;

  height: 24vh;
  margin-bottom: -7px;

  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* /* Shrinking for mobile */
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  .header h1 {
    font-size: 29px;
    margin-top: 27px;
  }
  .inner-header {
    height: auto;
  }
  .wave-headcontent button {
    height: 40px;
    width: 180px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    border: 1px solid orangered;
    background-color: orangered;
    color: white;
  }
}
.bannerimage {
  height: 50px;
}

@media screen and (max-width: 780px) {
  .text-div h1 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 35px;
  }
  .banner-div {
    display: flex;
    flex-direction: column-reverse;
  }
  .banner-div1 {
    display: none;
  }
  .digital-image {
    animation: none;
  }
  .round-div {
    border: 20px solid rgb(216, 218, 220);
    border-radius: 200px;
    width: 260px;
    height: 260px;
  }
  .digital-image {
    width: 230px;
    height: 219px;
    border-radius: 200px;
    object-fit: cover;
  }
  .expertise-style {
    height: auto;
  }
  .brand-div {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    height: auto;

    padding: 25px;
    background-color: white;
  }
  .brand-div:hover {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    height: auto;

    padding: 25px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: rgb(24, 30, 78);
    color: white;
  }
  .banner-div {
    border-radius: 0;
    transform: scale(1);
    height: auto;
  }

  .text-div h1 {
    font-size: 1.5rem;
  }
  .text-div {
    justify-content: baseline;
  }
  .text-div p {
    font-size: 1rem;
  }
  .text-div button {
    width: 200px;
    text-align: center;
    margin-top: 20px;
    margin-left: 0px;
  }
  .circle-div {
    position: absolute;
    height: 500px;
    width: 100%;
    border: 3px solid white;
    z-index: -1;
    top: 3%;
    left: 3%;
    border-radius: 50%;
    border-style: dashed;
  }
  .face-icons {
    height: 70px;
    width: 70px;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    top: 10%;
  }
  .linked-icons {
    display: none;
  }
  .insta-iconsdiv {
    height: 70px;
    width: 70px;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    left: 69%;
    top: 5%;
  }
  .insta-iconsdiv .fa-instagram {
    position: absolute;
    top: 24%;
    left: 30%;
    font-size: 35px;
    color: rgb(245, 107, 130);
  }
  .globetext-div1 {
    position: absolute;
    top: 20%;
    left: 0;
    padding: 15px;
  }
  .brand-text {
    font-size: 20px !important;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 600;
    color: #bb0b0b;
  }
}

@media screen and (min-width: 780px) {
  .discover-text {
    padding: 5px;
    border: 1px solid rgb(15, 15, 45);
    height: 35px;
    width: 35px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(52, 52, 82);
    padding: 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
  }
}
