#app {
  text-align: center;
  font-size: 60px;
  font-weight: 650;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  /* font-family: "ICA Rubrik"; */
  margin-top: 10px;
  background-clip: border-box;
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.text-animation {
  height: 150px;
  background-color: rgb(24, 30, 78);
}

.image-animation {
  background-color: rgb(24, 30, 78);
}

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: skyblue;
}
.fa-star {
  color: #bb0b0b;
  font-size: 18px;
}
.aboutus .span-text {
  font-size: 18px;
  font-weight: 600;
  color: #bb0b0b;
}
.border1 {
  border: 1px solid black;
}
.span-para {
  color: #44107a;
  font-weight: 500;
  font-size: 20px;
}
.aboutus p {
  font-weight: 400;
  font-style: inherit;
  letter-spacing: 1px;
  font-size: 20px;
}
.funtametal-text {
  color: #bb0b0b;
}
.vision-border {
  border: 1px solid rgb(238, 237, 254);
  height: 420px;
  background-color: rgb(238, 237, 254);
  border-radius: 10px;
}
.fa-eye {
  font-size: 50px;
  display: flex;
  justify-content: center;
  font-weight: 400;
}
.vision-text {
  font-size: 18px;
  font-style: inherit;
  letter-spacing: 1px;
}
.mission-border {
  border: 1px solid#ffe0cc;
  height: 420px;
  background-color: #ffe0cc;
  border-radius: 10px;
}
.fa-bullseye {
  font-size: 50px;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.vision-border {
  position: relative;
  overflow: hidden;
  background-color: #eeedfe; /* Default background color */
  transition: background-color 0.5s ease; /* Smooth transition */
}

.vision-border::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3d3568;
  transition: top 0.5s ease;
  z-index: 1;
}

.vision-border:hover::before {
  top: 0;
}

.vision-border:hover i,
.vision-border:hover h2,
.vision-border:hover p {
  color: white; /* Change text and icon color to white on hover */
}

.vision-border i,
.vision-border h2,
.vision-border p {
  position: relative;
  z-index: 2;
  transition: color 0.5s ease; /* Smooth color transition */
}

.values-border {
  /* border: 1px solid rgb(188, 187, 187); */
  height: 420px;
  /* background-color: rgb(159, 158, 158); */
  border-radius: 10px;
}
.values-border .fa-handshake {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 100px;
}
.values-border {
  position: relative;
  overflow: hidden;
  border: 1ps solid rgb(242, 244, 248);
  background-color: rgb(242, 244, 248); /* Default background color */
  transition: background-color 0.5s ease; /* Smooth transition */
}

.values-border::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: blue;
  transition: top 0.5s ease;
  z-index: 1;
}

.values-border:hover::before {
  top: 0;
}

.values-border:hover i,
.values-border:hover h2,
.values-border:hover p {
  color: white; /* Change text and icon color to white on hover */
}

.values-border i,
.values-border h2,
.values-border p {
  position: relative;
  z-index: 2;
  transition: color 0.5s ease; /* Smooth color transition */
}
.mission-border {
  position: relative;
  overflow: hidden;
  background-color: #ffe0cc; /* Default background color */
  transition: background-color 0.5s ease; /* Smooth transition */
}

.mission-border::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: orangered;
  transition: top 0.5s ease;
  z-index: 1;
}

.mission-border:hover::before {
  top: 0;
}

.mission-border:hover i,
.mission-border:hover h2,
.mission-border:hover p {
  color: white; /* Change text and icon color to white on hover */
}

.mission-border i,
.mission-border h2,
.mission-border p {
  position: relative;
  z-index: 2;
  transition: color 0.5s ease; /* Smooth color transition */
}
.choose-div {
  padding: 10px;
  background-color: #ffe0cc;
  width: 250px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.choose-div h5 {
  font-weight: 650;
}
.about-section .fa-circle-check {
  color: orangered;
  font-size: 30px;
}
.about-section div {
  width: 250px;
}
.support-div {
  margin-top: -60px;
}
.choose-text {
  color: orangered;
  font-weight: 550;
}
.about-image {
  height: 950px;
  width: 660px;
  object-fit: cover;
  object-position: center;
}
.about-section {
  display: flex;
}
/* mediaquery */
@media screen and (max-width: 780px) {
  .about-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .about-bgimage {
    background-position: center;
    background-size: cover;
    backface-visibility: visible;
    height: 420px;
  }
  .about-div {
    height: 150px;
    position: absolute;
    top: 100%;
    width: auto;
    background-color: white;
    border-radius: 10px;
  }
  .about-div img {
    width: 100%;
  }
  .about-div p {
    font-size: 16px;
    /* line-height: 30px; */
    color: black;
  }
  .vision-border {
    height: auto;
  }
  .mission-border {
    height: auto;
  }
  .values-border {
    height: auto;
  }
  #app {
    text-align: center;
    font-size: 28px;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-clip: border-box;
    text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white !important;
    text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
  .about-section div {
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .about-section {
    flex: none !important;
  }
  .text-animation {
    height: auto;
  }
}
