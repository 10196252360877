#app {
  text-align: center;
  font-size: 40px;
  font-weight: 650;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  /* font-family: "ICA Rubrik"; */
  margin-top: 10px;
  background-clip: border-box;
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}
.SMM-div {
  background-color: rgb(24, 30, 78);
  height: 150px;
}
.smm-banner {
  display: flex;
  flex-direction: column;
}
.previous-link {
  width: 100px;
}
.return-link {
  height: 40px;
  background-color: rgb(63, 63, 77);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.return-link .servicelinks {
  text-decoration: none;
  font-size: 18px;
  /* color: white; */
  margin-left: 15px;
}
.return-link span {
  font-size: 18px;
  color: white;
}
.smm-text {
  font-style: inherit;
  font-weight: 500;
  font-size: 20px;
}
/* Above css for All service page  */
@media screen and (max-width: 780px) {
  .SMM-div {
    height: auto;
  }
}
