.seo-head {
  color: #bb0b0b;
  font-size: 22px;
}
.seo-div p {
  font-size: 20px;
  font-style: inherit;
  letter-spacing: 1px;
}
.fa-circle-check {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.seo-head1 {
  color: #bb0b0b;
}
.seo-div img {
  border-radius: 13px;
}
.service-container {
  background-color: rgb(242, 244, 248);
}
.improve-text h2 {
  font-style: italic;
  font-family: "Courier New", Courier, monospace;
  font-weight: 550;
}
.improve-text p {
  font-size: 20px;
  font-style: inherit;
  letter-spacing: 1px;
  font-weight: 500;
}
.improve-text .seo-para {
  font-size: 18px;
  font-style: inherit;
  letter-spacing: 1px;

  font-weight: 400;
}
.seo-div .zoom-on-hover:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 780px) {
  .service-container img {
    height: 250px;
    width: 250px;
  }
  .product-list {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }
  .seo-head {
    color: red;
    font-size: 20px;
  }
  .improve-text h2 {
    font-size: 20px;
  }
}
