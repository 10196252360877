.border {
  border: 1px solid black;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bg-colour {
  background-color: rgb(236, 236, 236);
}
.contact-bg {
  background-image: url("../image/note-3205464_1280.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: fill;
  height: 510px;
  width: 100%;
}
.contact_absolute {
  position: absolute;
  top: 35%;
  right: 32%;
  text-align: center;
}
.text-style {
  position: absolute;
  top: 55%;
  right: 31%;
  text-align: center;
  /* font-family: "ICA Rubrik"; */
  font-size: 22px;
  font-weight: 550;
  font-variant: inherit;
}
#app1 {
  text-align: center;
  font-size: 40px;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: border-box;
  text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.fa-comment {
  font-size: 26px;
}
.talk-text {
  font-size: 26px;
  font-weight: 650;
  color: #bb0b0b;
}
.para-text {
  font-size: 18px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
}
.team-logo {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
/* formStyles.css */

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 10px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input,
.textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.input:focus,
.textarea:focus {
  border-color: skyblue;
  outline: none;
}
.error {
  color: red;
  font-size: 0.875em;
}
.contact-text {
  color: #bb0b0b;
}
.button {
  display: inline-block;
  padding: 10px 15px;
  font-size: 1em;
  color: #fff;
  background-color: #bb0b0b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 200px;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .fa-arrow-right {
  color: orangered;
} */
.link-div {
  background-color: rgb(24, 30, 35);
  height: auto;
}
.link-styles {
  /* color: white; */
  text-decoration: none;
  font-size: 18px;
}
.icons .fa-phone {
  color: #bb0b0b;
  font-size: 30px;
}
.map-div {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.map-container {
  background-color: rgb(236, 236, 236);
}
.contact-div {
  height: auto;
}
.logostyle {
  height: 120px;
  width: 120px;
  margin-left: 50px;
  border-radius: 50%;
}
.calenly-text {
  color: #bb0b0b;
  letter-spacing: 1px;
  font-weight: 700;
}
.contactbook-button {
  width: 200px;
  padding: 15px;
  font-size: 18px;
  font-weight: 550;
  border-radius: 10px;
  background-color: #bb0b0b;
  color: white;
  border: 1px solid #bb0b0b;
}
/* @media only screen and (max-width: 1000px) {
  .contact_absolute {
    position: absolute;
    top: 33% !important;

    left: 40% !important;
  }
  .text-style {
    position: absolute;
    top: 50%;
    left: 40% !important;
    text-align: center;
    font-family: "ICA Rubrik";
    font-size: 20px !important;
    font-weight: 550;
    margin-left: 48px;
  }
  .logostyle {
    height: 120px;
    width: 120px;
    margin-left: 60px !important;
    border-radius: 50%;
  }
  #app1 {
    text-align: center;
    font-size: 25px;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-clip: border-box;
    text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
} */
/* @media only screen and (min-width: 700px) {
  .contact_absolute {
    position: absolute;
    top: 29%;
    left: 10%;
  }
  .text-style {
    position: absolute;
    top: 50%;
    left: 16%;
    text-align: center;
    font-family: "ICA Rubrik";
    font-size: 16px;
    font-weight: 550;
    margin-left: 48px;
  }
} */
@media screen and (max-width: 767px) {
  .contact-bg {
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    height: 410px;
    width: 100%;
  }
  .contact_absolute {
    position: absolute;
    top: 29%;
    /* margin-left: 0px; */
    right: 15%;
  }
  .text-style {
    position: absolute;
    top: 45%;
    right: 20%;
    text-align: center;
    /* font-family: "ICA Rubrik"; */
    font-size: 16px;
    font-weight: 550;
    margin-left: 48px;
    font-variant: inherit;
    letter-spacing: 1px;
  }
  #app1 {
    text-align: center;
    font-size: 25px;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-clip: border-box;
    text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
  .link-div {
    height: auto;
  }
  .contact-div {
    height: auto;
  }
  .logostyle {
    height: 120px;
    width: 120px;
    margin-left: 60px;
    border-radius: 50%;
  }
  .form-container {
    max-width: auto;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    background-color: #f9f9f9;
  }
}
