.footer-link {
  text-decoration: none;
  color: white;
}
.fa-angles-right {
  color: #bb0b0b;
}
.aob {
  border: 1px solid black;
}
.footer-container {
  background-color: rgb(24, 30, 35);
}
.details-div .fa-phone-volume {
  color: #bb0b0b;
  font-size: 30px;
}
.details-div .fa-envelope {
  color: #bb0b0b;
  font-size: 30px;
}
.details-div .fa-location-dot {
  color: #bb0b0b;
  font-size: 30px;
}
.divider {
  color: white;
  margin-top: -20px;
}
.img-div {
  height: 190px;
  width: 200px;
  border: 20px solid gray;
  border-radius: 100px;
  background-color: gray;
}
.footer-img {
  border-radius: 100px;
  height: 150px;
  width: 150px;
}
.socialmeadia-links .fa-whatsapp {
  color: green;
  font-size: 30px;
}
.socialmeadia-links .fa-facebook {
  font-size: 30px;
  color: blue;
}
.socialmeadia-links .fa-instagram {
  color: rgb(169, 69, 86);
  font-size: 30px;
}
.socialmeadia-links .fa-linkedin-in {
  color: white;
  /* background-color: wheat; */
  font-size: 30px;
}
