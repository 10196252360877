.testimonials-text {
  /* font-style: italic; */
  font-family: "Courier New", Courier, monospace;
  color: #bb350b;
  font-weight: 550;
}
.carosil-image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
}
.carosil-text {
  font-style: inherit;
  letter-spacing: 1px;
  font-size: 18px;
  color: black;
  font-weight: 550px;
}
.mobiledisplay-div {
  display: none;
}
.carosil-image3 {
  height: 250px;
  width: 450px;
  border-radius: 50%;
  object-fit: cover;
}
.carosil-image4 {
  height: 250px;
  width: 650px;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .corousil-div {
    display: flex;
    flex-direction: row !important;
  }
  .corosildisply-div {
    display: none;
  }
  .mobile-image {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    object-fit: cover;
  }
  .boxshadows {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .mobiledisplay-div {
    display: block;
  }
}
