.copywriting-text {
  font-weight: 550;
  font-size: 20px;
  color: #bb0b0b;
}

@media screen and (max-width: 780px) {
  .web-head {
    font-size: 24px;
  }
}
