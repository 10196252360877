.webdevelop p {
  font-size: 18px;
  font-style: inherit;
  letter-spacing: 1px;
}
.web-text {
  font-size: 18px;
  font-style: inherit;
  letter-spacing: 1px;
}
.static-image {
  height: 400px;
  width: 400px;
  border-radius: 10px;
}
.static-image {
  transition: transform 0.3s ease;
}

.static-image.zoom-on-hover:hover {
  transform: scale(1.05);
}
.zoom-on-hover:hover {
  transform: scale(1.05);
}
.web-head {
  font-style: italic;
  font-family: "Courier New", Courier, monospace;
  font-weight: 550;
  font-size: 32px;
}
@media screen and (max-width: 780px) {
  .static-image.zoom-on-hover:hover {
    transform: scale(1);
  }
}
